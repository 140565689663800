<template>
	<div>
			<Card>
				<template #title>
					<h4>
						{{$t('Información Básica')}} ({{$t('Beneficiario')}})
					</h4>
				</template>
				<template #content>
					<div class="p-fluid p-formgrid p-grid" v-if="edicion">
						<div class="p-field p-col-12 p-md-2">
							<label>{{$t('Número ID')}}</label>
							<InputText v-model="inf.code" type="text"/>
							<small v-show="!inf.code" class="p-error">{{$t('required')}}</small>
						</div>
						<div class="p-field p-col-12 p-md-3">
							<label>{{$t('Nombre Completo')}}</label>
							<InputText v-model="inf.nombre" type="text"/>
							<small v-show="!inf.nombre" class="p-error">{{$t('required')}}</small>
						</div>
						<div class="p-field p-col-12 p-md-3">
							<label>{{$t('Correo')}}</label>
							<InputText v-model="inf.correo" type="email"/>
							<small v-show="!inf.correo" class="p-error">{{$t('required')}}</small>
						</div>
						<div class="p-field p-col-12 p-md-2">
							<label>{{$t('Teléfono móvil')}}</label>
							<InputMask v-model="inf.celular" mask="+99999 999999"/>
							<small class="p-error" v-if="i18n.locale() == 'es'">Incluir código del país</small>
							<small class="p-error" v-if="i18n.locale() == 'en'">Include country code</small>                    
						</div>
						<div class="p-field p-col-12 p-md-2">
							<label>{{$t('Teléfono privado')}}</label>
							<InputMask v-model="inf.tlflocal" mask="+99999 999999"/>
							<small class="p-error" v-if="i18n.locale() == 'es'">Incluir código del país</small>
							<small class="p-error" v-if="i18n.locale() == 'en'">Include country code</small>                    
						</div>     
						<div class="p-field p-col-12 p-md-2">
							<label for="state">{{$t('Marca')}}</label>
							<InputText v-model="inf.marca" type="text"/>
							<small v-show="!inf.marca" class="p-error">{{$t('required')}}</small>
						</div>
						<div class="p-field p-col-12 p-md-2">
							<label for="state">{{$t('Módelo')}}</label>
							<InputText v-model="inf.modelo" type="text"/>
							<small v-show="!inf.modelo" class="p-error">{{$t('required')}}</small>
						</div>
						<div class="p-field p-col-12 p-md-1">
							<label for="state">{{$t('Año')}}</label>
							<InputMask v-model="inf.year" mask="9999"/>
							<small v-show="!inf.year" class="p-error">{{$t('required')}}</small>
						</div>
						<div class="p-field p-col-12 p-md-3">
							<label>{{$t('Nº placa')}}</label>
							<InputText v-model="inf.placa" type="text"/>
							<small v-show="!inf.placa" class="p-error">{{$t('required')}}</small>
						</div>
						<div class="p-field p-col-12 p-md-4">
							<label for="state">{{$t('Tipo de Vehículo')}}</label>
                            <Dropdown v-model="inf.tipo" :options="tipos" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')"></Dropdown>
							<small v-show="inf.tipo==null" class="p-error">{{$t('required')}}</small>
						</div>
					</div>
					<div class="p-fluid p-formgrid p-grid" v-else>
						<div class="p-col-6">	
							<div class="p-grid card">
								<div class="p-col-6 p-mb-2">
									{{$t('Número ID')}}: <strong>{{inf.code}}</strong> 			
								</div>
								<div class="p-col-12 p-mb-2">
									{{$t('Nombre Completo')}}: <strong>{{inf.nombre}}</strong> 			
								</div>
								<div class="p-col-6 p-mb-2">
									{{$t('Teléfono móvil')}}: <strong>{{inf.celular}}</strong> 			
								</div>
								<div class="p-col-6 p-mb-2">
									{{$t('Teléfono privado')}}: <strong>{{inf.tlflocal}}</strong> 			
								</div>
								<div class="p-col-12 p-mb-2">
									{{$t('Correo')}}: <strong>{{inf.correo}}</strong> 			
								</div>
							</div>
						</div> 
						<div class="p-col-6">
							<div class="p-grid card">
								<div class="p-col-6 p-mb-2">
									{{$t('Marca')}}: <strong>{{inf.marca}}</strong>			
								</div>
								<div class="p-col-6 p-mb-2">
									{{$t('Módelo')}}: <strong>{{inf.modelo}}</strong>			
								</div>
								<div class="p-col-6 p-mb-2">
									{{$t('Año')}}: <strong>{{inf.year}}</strong>			
								</div>
								<div class="p-col-6 p-mb-2">
									{{$t('Nº placa')}}: <strong>{{inf.placa}}</strong>
								</div>
								<div class="p-col-12 p-mb-2" v-if="i18n.locale() == 'es'">
									{{$t('Tipo de Vehículo')}}: <strong v-if="inf.tipo">{{tipos.filter(val => val.code == inf.tipo)[0].nombre}}</strong> 			
								</div>
								<div class="p-col-12 p-mb-2" v-else>
									{{$t('Tipo de Vehículo')}}: <strong v-if="inf.tipo">{{tipos.filter(val => val.code == inf.tipo)[0].name}}</strong> 			
								</div>
							</div>		
						</div>
					</div>
				</template>
			</Card>
	</div>
</template>

<script>
	import { useI18nPlugin } from '@unify/vuex-i18n';

	export default {
        props: {
            datos: {
                type: Object,
                default: null,
            },
            edit: {
                type: Boolean,
                default: false,
            },
			tipo_veh: {
                type: Array,
                default: null,
            },

        },
        data() {
			return {
				i18n: null,
                edicion: this.edit,
                inf: this.datos,
				tipos: this.tipo_veh,
			}
		},
		created() {
			this.i18n = useI18nPlugin();
			if(this.edit && this.inf.tipo){
				this.inf.tipo = this.tipos.filter(val => val.code == this.inf.tipo)[0];
			}
		},
        methods: {
			
			
        }
	}
</script>
